* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

::selection {
  text-stroke: 2px black;
  -webkit-text-stroke: 2px #fff;
}

hr {
  border: 0px solid currentColor;
}

/*
main {
  scroll-snap-type: y mandatory;
  height: 100vh;
  overflow-y: scroll;
  position: relative;
}

main > * {
  scroll-snap-align: center;
}
*/

#app {
  padding-top: 350px;
}

img {
  max-width: 100%;
}

body {
  background: var(--color-primary);
  font-size: var(--font-size-p);
  color: white;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  font-family: "tt-travels-next";
}

a {
  color: currentColor;
  text-decoration: none;
}

.markdown a {
  text-decoration: underline;
}

a:hover {
  text-decoration: underline;
}

.a.a--no-hover:hover {
  text-decoration: none;
}

h1,
h2,
h3,
h4 {
  font-family: "tt-ricks";
  margin: 1rem 0;
}

h1 {
  font-size: var(--font-size-1);
}

h2 {
  font-size: var(--font-size-2);
}

h3 {
  font-size: var(--font-size-3);
}

h4 {
  font-size: var(--font-size-4);
  font-family: "athelas", serif;
  margin-left: 2rem;
}

h5 {
  font-size: var(--font-size-5);
}

p {
  font-weight: 400;
  font-size: var(--font-size-p);
  line-height: 1.5;
}
