.list {
  margin: 0;
  padding: 0;
}

.list li {
  padding: 10px;
  border-bottom: 4px solid currentColor;
}

.list li:hover {
  background-color: var(--color-primary);
  color: white;
}
