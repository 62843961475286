@keyframes ticker {
  0% {
    transform: translate3d(-8%, 0, 0);
    visibility: visible;
  }

  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

.ticker-wrap {
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  background-color: rgba(#000, 0.9);
  padding-left: 100%;
  box-sizing: content-box;
}

.ticker-wrap .ticker {
  display: inline-block;
  height: 30rem;
  line-height: 30rem;
  white-space: nowrap;
  padding-right: 100%;
  box-sizing: content-box;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: ticker;
  animation-duration: 120s;
}

.ticker-wrap .ticker__item {
  display: inline-block;
}

.ticker-wrap .logo {
  font-family: "tt-ricks";
  font-size: 22vw;
  margin: 0;
}

@media (min-width: 800px) {
  .ticker-wrap .logo {
    font-size: 22vw;
  }
}
