.section {
  overflow: hidden;
}

.section.section--full-height {
  min-height: 100vh;
  position: relative;
  width: 100vw;
}

.section.section--semi-height {
  min-height: 95vh;
  position: relative;
  width: 100vw;
}

.section.section--inverted {
  background: white;
  color: var(--color-primary);
}

.section.section--half-height {
  min-height: 50vh;
}
