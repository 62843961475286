.details {
  outline: none;
  list-style: none;
}

.details summary {
  outline: none;
  padding: 0;
  background-image: none;
  -webkit-appearance: none;
  cursor: pointer;
}

.details summary::-webkit-details-marker {
  display: none;
}

.details summary:hover h2 {
  text-decoration: underline;
}

.details div {
  padding-left: 30px;
  max-width: 700px;
}
