.container {
  padding-left: 30px;
  padding-right: 30px;
  max-width: 600px;
  margin: 0 auto;
  display: block;
  border: none;
}

.container--full {
  max-width: 100%;
}

.container--wide {
  max-width: 1200px;
}

.container--left {
  margin-left: 0;
  margin-right: 0;
}
