html,
body {
  font-size: 16px;
}

:root {
  /* SPACE */
  --space-unit: 1em;
  --space-1: calc(0.25 * var(--space-unit));
  --space-2: calc(0.5 * var(--space-unit));
  --space-3: calc(0.75 * var(--space-unit));
  --space-4: calc(1.25 * var(--space-unit));
  --space-5: calc(2 * var(--space-unit));
  --space-6: calc(3.25 * var(--space-unit));
  --space-7: calc(5.25 * var(--space-unit));

  /* COLORS */
  --color-primary: #AD6A40;
  --color-primary-light: #325b98;
  --color-primary-dark: #542D28;
  --color-paragraph: #7B6954;
  --color-gray: #eee;
  --color-gray-light: #fafafa;
  --color-gray-dark: #c3c3c3;
  --color-font-light: #7B5D54;

  /* BOX MODEL */
  --border-radius: 4px;
  --box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);

  /* NAVBAR */
  --navbar-height: 100px;

  /* TEXT */
  --text-base-size: 1em;
  --text-scale-ratio: 1.8;

  --font-size-1: 5rem;
  --font-size-2: 2.7rem;
  --font-size-3: 1.2rem;
  --font-size-4: 1rem;
  --font-size-5: 0.7rem;
  --font-size-p: 1.1rem;
}

/* BREAKPOINTS */

@media (min-width: 600px) {
  :root {
    --font-size-1: 14vw;
    --font-size-2: 9.5vw;
    --font-size-3: 16rem;
    --font-size-4: 14rem;
    --font-size-5: 12rem;
    --font-size-p: 2.7vw;
  }
}

@media (min-width: 1100px) {
  :root {
    --font-size-1: 9rem;
    --font-size-2: 6rem;
    --font-size-3: 3rem;
    --font-size-4: 1rem;
    --font-size-5: 0.7rem;
    --font-size-p: 1.5rem;
  }
}
